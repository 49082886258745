import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

const WAIT_INTERVAL = 1000;

export default class extends Controller {

    static targets = ['workingDate'];

    timer = null;

    async change_working_date() {
        // TODO : how to show loading skeleton again?
        const workingDate = this.workingDateTarget.value;
        const request = new FetchRequest('get', '/admin/milieu_rubrieken/list', {query: {working_date: workingDate}, responseKind: 'turbo-stream'});
        const response = await request.perform();
        if (response.ok) {
            
        }
    }

    change_only_changed() {
        clearTimeout(this.timer);
        this.filterRubrieken();
    }

    change_only_problems() {
        clearTimeout(this.timer);
        this.filterRubrieken();
    }

    search(event) {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.filterRubrieken.bind(this), WAIT_INTERVAL);
    }

    filterRubrieken() {
        const milieuRubrieken = document.querySelectorAll('#milieu_rubrieken_list > #milieu_rubriek_list > .milieu_rubriek');

        let publishing_date = document.querySelector('#publishing_date').value;
        publishing_date = Date.parse(publishing_date);
        const only_changed = document.querySelector('#only_changed_checkbox').checked;
        const only_problems = document.querySelector('#only_problems_checkbox').checked;
        const searchTerm = document.querySelector('#milieurubriek-search').value;
        if (searchTerm) {
            const searchTermRegex = new RegExp(searchTerm.trim().toLowerCase(), 'i');

            milieuRubrieken.forEach(function(milieuRubriek) {
                const passed = this.filterRubriek(milieuRubriek, searchTermRegex, publishing_date, only_changed, only_problems);
                this.setVisibilityRubriek(milieuRubriek, passed);
            }.bind(this));
        } else {
            milieuRubrieken.forEach(function(milieuRubriek) {
                const passed = this.filterRubriek(milieuRubriek, null, publishing_date, only_changed, only_problems);
                this.setVisibilityRubriek(milieuRubriek, passed);
            }.bind(this));
        }
        if ($('.milieu_rubriek[aria-hidden=false]').length == 0) {
            $('#empty_list').toggleClass('d-none', false);
        } else {
            $('#empty_list').toggleClass('d-none', true);
        }
    }

    filterRubriek(rubriek, searchTermRegex, publishingDate, only_changed, only_problems) {
        const ignore_problem = $(rubriek).data('ignore-problem');
        const type = $(rubriek).data('rubriek-type');
        let valid_from = $(rubriek).data('valid-from');
        valid_from = Date.parse(valid_from);
        const title = rubriek.querySelector('.milieu_rubriek_title').innerHTML;

        const children = rubriek.querySelectorAll('& > .milieu_rubriek_list > .milieu_rubriek');
        if (children.length == 0) {
            if (only_problems && (ignore_problem === true || type === 4 || type === 6)) {
                this.setVisibilityRubriek(rubriek, false);
                return false;
            }
            if (only_changed && valid_from < publishingDate) {
                this.setVisibilityRubriek(rubriek, false);
                return false;
            }
            if (searchTermRegex !== undefined && searchTermRegex !== null && !searchTermRegex.test(title.toLowerCase())) {
                this.setVisibilityRubriek(rubriek, false);
                return false;
            }
            this.setVisibilityRubriek(rubriek, true);
            return true;
        } else {
            let all_passed = false;
            children.forEach(function(child) {
                const passed = this.filterRubriek(child, searchTermRegex, publishingDate, only_changed, only_problems);
                all_passed ||= passed;
            }.bind(this));
            if (all_passed) {
                this.setVisibilityRubriek(rubriek, true);
                return true;
            } else {
                if (only_problems && (ignore_problem || type === 2 || type === 3)) {
                    this.setVisibilityRubriek(rubriek, false);
                    return false;
                }
                if (only_changed && valid_from < publishingDate) {
                    this.setVisibilityRubriek(rubriek, false);
                    return false;
                }
                if (searchTermRegex !== undefined && searchTermRegex !== null && !searchTermRegex.test(title.toLowerCase())) {
                    this.setVisibilityRubriek(rubriek, false);
                    return false;
                }
                this.setVisibilityRubriek(rubriek, true);
                return true;
            }
        }
    }

    setVisibilityRubriek(rubriek, visible) {
        rubriek.setAttribute('aria-hidden', visible ? 'false' : 'true');
        rubriek.classList.toggle('d-none', !visible);
    }
}