import { Controller } from "@hotwired/stimulus"
import { post, put, destroy } from "@rails/request.js"

export default class extends Controller {

    async createQuestion(event) {
        event.preventDefault();
        const id = $(event.currentTarget).data('id');
        const version_id = $(event.currentTarget).data('version-id');
        const rubriek_type = $(event.currentTarget).data('rubriek-type');
        const valid_from = $(event.currentTarget).data('valid-from');
        const description = $(event.currentTarget).data('description');
        const response = await post(`/admin/questions`, { body: JSON.stringify({rubriek: {id: id, version_id: version_id, eind_rubriek: this.isEindRubriek(rubriek_type), valid_from: valid_from, description: description}}), contentType: 'application/json', responseKind: 'json'})
        if (response.ok) {
            const body = await response.json;
            Turbo.visit(body.redirect_url);
        }
    }

    editQuestion(event) {
        event.preventDefault();
        const id = $(event.currentTarget).data('id');
        const version_id = $(event.currentTarget).data('version-id');
        Turbo.visit(encodeURI(`/admin/questions?filter[filters][0][field]=rubriek_versie_id&filter[filters][0][operator]=eq&filter[filters][0][value]=${version_id}`));
    }

    toggleIgnoreProblems(event) {
        event.preventDefault();
        const id = $(event.currentTarget).data('id');
        const version_id = $(event.currentTarget).data('version-id');
        const rubrieknummer = $(event.currentTarget).data('rubrieknummer');
        const ignore_problem = event.currentTarget.checked;
        if (ignore_problem) {
            const request = put(`/admin/milieu_rubrieken/${id}`, { body: JSON.stringify({rubriek: {id: id, version_id: version_id, rubrieknummer: rubrieknummer, ignore_problem: ignore_problem}}) , contentType: 'application/json', responseKind: 'json'});
            request.then((request) => {
                if (request.response.ok) {
                    const milieu_rubriek = document.querySelector(`.milieu_rubriek[data-id="${id}"]`);
                    if (milieu_rubriek) {
                        $(milieu_rubriek).data('ignore-problem', ignore_problem);
                    }
                } else {
                    if (request.response.status == 404) {
                        const request_2 = post('/admin/milieu_rubrieken/', { body: JSON.stringify({rubriek: {id: id, version_id: version_id, rubrieknummer: rubrieknummer, ignore_problem: ignore_problem}}), contentType: 'application/json', responseKind: 'json'});
                        request_2.then((request) => {
                            if (request.response.ok) {
                                const milieu_rubriek = document.querySelector(`.milieu_rubriek[data-id="${id}"]`);
                                if (milieu_rubriek) {
                                    $(milieu_rubriek).data('ignore-problem', ignore_problem);
                                }
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            const request = destroy(`/admin/milieu_rubrieken/${id}`, { body: JSON.stringify({rubriek: {id: id, version_id: version_id, rubrieknummer: rubrieknummer, ignore_problem: ignore_problem}}), content_type: 'application/json', responseKind: 'json'});
            request.then((request) => {
                if (request.response.ok) {
                    const milieu_rubriek = document.querySelector(`.milieu_rubriek[data-id="${id}"]`);
                    if (milieu_rubriek) {
                        $(milieu_rubriek).data('ignore-problem', ignore_problem);
                    }
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    isEindRubriek(rubriek_type) {
        switch(rubriek_type) {
            case 1:
            case 2:
            case 3:
                return false;
                break;
            default:
                return true;
        }
    }
}
