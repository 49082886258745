import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["collapsable"];

    toggle_collapse(event) {
        event.preventDefault();

        const collapsable = this.collapsableTarget;
        if ($(collapsable).hasClass("collapsed")) {
            $(collapsable).removeClass("collapsed");
        } else {
            $(collapsable).addClass("collapsed");
        }
        this.select(event);
    }

    select(event) {
        event.preventDefault();

        const parentElement = event.currentTarget.parentElement;
        const selected_rubrieken = document.querySelectorAll('.milieu_rubriek.selected');
        selected_rubrieken.forEach(rubriek => {rubriek.classList.toggle('selected', false)});
        parentElement.classList.toggle('selected', true);
        
        const id = $(parentElement).data('id');
        const version_id = $(parentElement).data('version-id');
        const ignore_problem = $(parentElement).data('ignore-problem');
        const rubriek_type = $(parentElement).data('rubriek-type');
        const valid_from = $(parentElement).data('valid-from');
        const rubrieknummer = $(parentElement).data('rubrieknummer');
        const description = $(parentElement).data('beschrijving');
        const klasse = $(parentElement).data('klasse');
        const opmerkingen = $(parentElement).data('opmerkingen');
        const coordinator = $(parentElement).data('coordinator');
        const audit = $(parentElement).data('audit');
        const jaarverslagen = $(parentElement).data('.jaarverslag');
        const vlarebo = $(parentElement).data('vlarebo');

        const ignore_problem_checkbox = document.querySelectorAll('#ignore_problem_checkbox');
        const create_question_button = document.querySelectorAll('#create_question_button');
        const edit_question_button = document.querySelectorAll('#edit_question_button');
        const rubrieknummer_info = document.querySelectorAll('#rubrieknummer');
        const description_info = document.querySelectorAll('#beschrijving');
        const klasse_info = document.querySelectorAll('#klasse');
        const opmerkingen_info = document.querySelectorAll('#opmerkingen');
        const coordinator_info = document.querySelectorAll('#coordinator');
        const audit_info = document.querySelectorAll('#audit');
        const jaarverslagen_info = document.querySelectorAll('#jaarverslag');
        const vlarebo_info = document.querySelectorAll('#vlarebo');
        
        ignore_problem_checkbox.forEach(cb => {
            cb.checked = ignore_problem;
            $(cb).data('id', id);
            $(cb).data('version-id', version_id);
            $(cb).data('rubrieknummer', rubrieknummer);
        });
        create_question_button.forEach(b => {
            switch(rubriek_type) {
                case 1:
                case 3:
                case 5:
                    $(b).show();
                    break;
                default:
                    $(b).hide();
            }
            $(b).data('id', id);
            $(b).data('version-id', version_id);
            $(b).data('rubriek-type', rubriek_type);
            $(b).data('valid-from', valid_from);
            $(b).data('description', description);
        });
        edit_question_button.forEach(b => {
            switch(rubriek_type) {
                case 2:
                case 4:
                    $(b).show();
                    break;
                default:
                    $(b).hide();
            }
            $(b).data('id', id);
            $(b).data('version-id', version_id);
        });
        rubrieknummer_info.forEach(info =>{info.innerHTML = rubrieknummer});
        description_info.forEach(info => {info.innerHTML = description});
        if (klasse !== undefined) {
            klasse_info.forEach(info => {info.innerHTML = klasse});
        } else {
            klasse_info.forEach(info => {info.innerHTML = ''});
        }
        if (opmerkingen !== undefined) {
            opmerkingen_info.forEach(info => {info.innerHTML = opmerkingen});
        } else {
            opmerkingen_info.forEach(info => {info.innerHTML = ''});
        }
        if (coordinator !== undefined) {
            coordinator_info.forEach(info => {info.innerHTML = coordinator});
        } else {
            coordinator_info.forEach(info => {info.innerHTML = ''});
        }
        if (audit !== undefined) {
            audit_info.forEach(info => {info.innerHTML = audit});
        } else {
            audit_info.forEach(info => {info.innerHTML = ''});
        }
        if (jaarverslagen !== undefined) {
            jaarverslagen_info.forEach(info => {info.innerHTML = jaarverslagen});
        } else {
            jaarverslagen_info.forEach(info => {info.innerHTML = ''});
        }
        if (vlarebo !== undefined) {
            vlarebo_info.forEach(info => {info.innerHTML = vlarebo});
        } else {
            vlarebo_info.forEach(info => {info.innerHTML = ''});
        }
    }
}
