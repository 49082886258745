import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';
import { strToDom } from '../utils/strToDom';

window.bs = bootstrap;


export default class extends Controller {
    static targets = ['questionsBtn'];
    static values = {
        url: String
    }

    collapses;
    searchElement;

  connect() {
      this.fetch(this.urlValue);
  }

  fetch(url) {
      return $.ajax({
          dataType: 'html',
          type: 'GET',
          url: url,
          // url: "/admin/activities.turbo_stream",
          success: function (data, textStatus, jqXHR) {
              const parser = new DOMParser();
              const doc = parser.parseFromString(data, "text/html");
              $(this.element).find('.accordion').html($(doc.querySelector('template')).html());
              this.initActivitiesForm();
          }.bind(this)
      });
  }

  initActivitiesForm() {

    var $form = this.element.querySelector('form[name="form-activities"]');
    if ($form) {
        var $activities = $form.querySelectorAll('input:is([type="checkbox"], [type="radio"])');

        // Form search
        var $search = $form.querySelector('.from-control-activity-search');
        this.searchElement = $search;

        // init accordion instances once
        this.collapses = Array.from($activities)
            .map($activity => $activity.closest('.accordion-collapse'))
            // .filter(($accordion, index, arr) => arr.indexOf($accordion) === index)
            .map($accordion => new bootstrap.Collapse($accordion, {
                toggle: false
            }));

        $search.addEventListener('input', function(e){
            var value = e.currentTarget.value.trim();
            
            // no value = show all
            if (e.currentTarget.value) {
                var regex = new RegExp(e.currentTarget.value.toLowerCase(), 'i');
                
                $activities.forEach($activity => {
                    var testResult = regex.test($activity.id.toLowerCase());
                    var $item = $activity.closest('.form-check');
                    var $collapse = $item.closest('.accordion-collapse');
                    var $accordion = $collapse.closest('.accordion-item');
                    
                    // show-hide option
                    $item.setAttribute('aria-hidden', !testResult ? 'true' : 'false');
                    $item.classList.toggle('d-none', !testResult);
    
                    // if accordion has no items to show => hide
                    var hasItems = !!$accordion.querySelectorAll('.form-check:not(.d-none)').length;
                    $accordion.setAttribute('aria-hidden', !hasItems ? 'true' : 'false');
                    $accordion.classList.toggle('d-none', !hasItems);
    
                    // var collapse = bootstrap.Collapse.getInstance($collapse);
                    // collapse.show();
                    var $button = $accordion.querySelector('.accordion-button');
                    if(hasItems && $button.getAttribute('aria-expanded') == 'false') {
                        $($button).trigger("click");
                    }
                });
            } else {
                this.collapses.forEach(collapse => {
                    var element = collapse._element;
                    var $items = element.querySelectorAll('.form-check');
                    var $accordion = element.closest('.accordion-item');

                    $items.forEach((item) => {
                        item.setAttribute('aria-hidden', 'false');
                        item.classList.toggle('d-none', false);
                    });

                    $accordion.setAttribute('aria-hidden', 'false');
                    $accordion.classList.toggle('d-none', false);

                    // collapse.hide();
                    var $button = $accordion.querySelector('.accordion-button');
                    if($button.getAttribute('aria-expanded') == 'true') {
                        $($button).click();
                    }
                });
            }
        }.bind(this));

        // Activities toggle
        // new bootstrap.Collapse('.collapsed-selected-activities');
        // $selectedActivities = $form.querySelector('.selected-activities')
        $activities.forEach($activity => {
            $activity.addEventListener('input', this.activityClicked.bind(this));
            if ($activity.checked) {
              this.toggleActivity($activity.value, true);
            }
        });

        let activity_ids = sessionStorage.getItem("selectedActivities");
        if (activity_ids) {
            activity_ids = JSON.parse(activity_ids);
            activity_ids.forEach(activity_id => {
                this.toggleActivity(activity_id, true);
            });
        }

    }

  }

  activityClicked(event) {
      this.toggleActivity(event.currentTarget.value, event.currentTarget.checked);
  }

  toggleActivity(value, checked) {
    var $form = document.querySelector('form[name="form-activities"]');
    var $option = $form.querySelector(`input[type="checkbox"][value="${value}"]`);
    var $selectedActivities = $form.querySelector('.selected-activities');
    var $counter = $form.querySelector('.activities-counter');
    var $collapseBtn = $form.querySelector('.collapsed-selected-activities-button')
    var $selectedActivity = $selectedActivities.querySelector(`.selected-activity[data-value="${value}"]`);
    var $basketBadge = document.querySelector('.activity-basket .badge');
    var $modalContent = document.querySelector('#selected-activities-modal .modal-body');

    // set option
    if ($option) $option.checked = checked;

    // manage item in selected activities
    if (checked && !$selectedActivity) {
        // generate item & add to selected activities
        $selectedActivity = strToDom(`<div class="selected-activity mb-2" data-value="${value}"><button class="btn btn-link p-0" type="button" role="button"><span class="icon-tag-delete me-2"></span></button>${$option.parentNode.querySelector('.form-check-label').innerHTML}</div>`);
        $selectedActivities.appendChild($selectedActivity);

        // clone item and add to modal
        var $modalItem = $selectedActivity.cloneNode(true);
        $modalContent.appendChild($modalItem);
        
        // add listeners to both items
        $selectedActivity.querySelector('button').addEventListener('click', (e) => {
            this.toggleActivity(e.currentTarget.parentNode.dataset.value, false);
        });
        $modalItem.querySelector('button').addEventListener('click', (e) => {
            this.toggleActivity(e.currentTarget.parentNode.dataset.value, false);
        });

        let activity_ids = sessionStorage.getItem("selectedActivities");
        if (!activity_ids) {
            activity_ids = [];
        } else {
            activity_ids = JSON.parse(activity_ids);
        }
        if (!activity_ids.includes(value)) {
            activity_ids.push(value);
        }
        // save to session storage
        sessionStorage.setItem("selectedActivities", JSON.stringify(activity_ids));

    }
    else if (!checked && $selectedActivity) {
        $selectedActivity.remove();

        // remove from modal
        var $item = $modalContent.querySelector(`.selected-activity[data-value="${value}"]`);
        if ($item) $item.remove();

        let activity_ids = sessionStorage.getItem("selectedActivities");
        if (activity_ids) {
            activity_ids = JSON.parse(activity_ids);
            let new_activity_ids = activity_ids.filter((id) => id !== value );
            sessionStorage.setItem("selectedActivities", JSON.stringify(new_activity_ids));
        }
    }

    // Reset selected answers because page numbers change
    sessionStorage.setItem("selectedAnswers", JSON.stringify({}));

    // update counter
    var activitiesCount = $selectedActivities.children.length;
    $counter.innerHTML = activitiesCount;
    $basketBadge.querySelector('.value').innerHTML = activitiesCount;
    this.questionsBtnTarget.disabled = (activitiesCount === 0);

    // show/hide badge on activity-basket
    $basketBadge.classList.toggle('d-none', !activitiesCount);
    $collapseBtn.classList.toggle('d-none', !activitiesCount);

    // toggle empty message in modal
    $modalContent.firstElementChild.classList.toggle('d-none', activitiesCount);
  }

}
