// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import CookiesController from './cookies_controller.js'
application.register("cookies", CookiesController)

import ActivitiesController from './activities_controller.js'
application.register("activities", ActivitiesController)

import QuestionsController from './questions_controller.js'
application.register("questions", QuestionsController)

import BackendActivitiesController from './backend_activities_controller'
application.register("backend-activities", BackendActivitiesController)

import RubriekenListController from './rubrieken_list_controller.js'
application.register("rubrieken-list", RubriekenListController)

import RubriekenListItemController from './rubrieken_list_item_controller.js'
application.register("rubrieken-list-item", RubriekenListItemController)

import RubriekenListItemInfoController from './rubrieken_list_item_info_controller.js'
application.register("rubrieken-list-item-info", RubriekenListItemInfoController)

import Grids__QuestionsGridController from './grids/questions_grid_controller'
application.register("grids--questions-grid", Grids__QuestionsGridController)

import Grids__AnswersGridController from './grids/answers_grid_controller'
application.register("grids--answers-grid", Grids__AnswersGridController)
