import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie";

export default class extends Controller {

    initialize() {
        this.cookiesWall = this.element;
        if (this.cookiesAllowed()) {
            this.appendGTagCode();
        }
        this.addButtonBehaviors();
    }

    cookiesAllowed() {
        return Cookies.get('allow_cookies') === 'yes';
    }

    addButtonBehaviors() {
        const accept = this.cookiesWall.querySelector('.accept')
        if (accept) {
            accept.addEventListener('click', () => this.allowCookies(true));
        }
        const reject = this.cookiesWall.querySelector('.reject')
        if (reject) {
            reject.addEventListener('click', () => this.allowCookies(false));
        }
    }

    appendGTagCode() {
        const async_script_el = document.createElement('script');
        async_script_el.async = true;
        async_script_el.src = 'https://www.googletagmanager.com/gtm.js?id=G-J1R016TK8K';
        const script_el = document.createElement('script');
        script_el.innerHTML = "window.dataLayer = window.dataLayer || [];" +
        "function gtag(){dataLayer.push(arguments);}" +
        "gtag('js', new Date());" +
        "gtag('config', 'G-J1R016TK8K');";
        document.getElementsByTagName('head')[0].after(script_el);
        document.getElementsByTagName('head')[0].after(async_script_el);
    }

    allowCookies(allow) {
        if (allow) {
            Cookies.set('allow_cookies', 'yes', {
                expires: 365
            });
        } else {
            Cookies.set('allow_cookies', 'no', {
                expires: 365
            });
        }
        this.cookiesWall.classList.add('hidden');
    }
}