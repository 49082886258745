import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grids--questions-grid"
export default class extends Controller {
  static values = {
    url: String,
    gridName: { type: String, default: 'questions-grid' },
    hideLinkCommand: { type: Boolean, default: true },
    hideUnlinkCommand: { type: Boolean, default: true },
    hideCopyCommand: { type: Boolean, default: false },
    hideSequenceColumn: { type: Boolean, default: true },
    hideProblemsColumn: { type: Boolean, default: false },
    hideShowCommand: { type: Boolean, default: true },
    hideEditCommand: { type: Boolean, default: false },
    hideDestroyCommand: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
    isGroupable: { type: Boolean, default: true }
  }

  static version = 1;

  handleGridEvent = (event) => {
    setTimeout(() => {
      this.saveGridState();
    });
  }

  sequenceNumberEditor = (container, options) => {
    $(`<input name="${options.field}"/>`).appendTo(container).kendoNumericTextBox({
      format: '{0:n0}',
      step: 1,
      min: 1
    });
  }

  problemCountFilterCellTemplate = (args) => {
    args.element.kendoNumericTextBox({
      decimals: 0,
      format: '{0:n0}',
      restrictDecimals: true,
      step: 1,
      min: 0
    });
  }

  actionColumnIndex;
  activityId;
  commandButtonWidth = 50;
  gridConfig = {
    columnMenu: {
      filterable: false,
      sortable: false
    },
    columns: [
      {
        field: 'id',
        type: 'number',
        groupable: false,
        title: 'ID'
      },
      {
        field: 'question_text',
        type: 'string',
        encoded: false,
        groupable: false,
        title: 'Vraag'
      },
      {
        field: 'rubriek_id',
        type: 'number',
        hideOnGroup: true,
        title: 'Rubriek ID',
        hidden: true
      },
      {
        field: 'rubriek_versie_id',
        type: 'number',
        hideOnGroup: true,
        title: 'Rubriekversie ID',
        hidden: true
      },
      {
        field: 'rubriek_nummer',
        type: 'string',
        hideOnGroup: true,
        title: 'Rubrieknummer'
      },
      {
        field: 'is_generic',
        type: 'boolean',
        hideOnGroup: true,
        title: 'Generisch?'
      },
      {
        field: 'created_at',
        type: 'date',
        format: '{0:dd/MM/yyyy HH:mm:ss}',
        hideOnGroup: true,
        title: 'Created',
        hidden: true
      },
      {
        field: 'updated_at',
        type: 'date',
        format: '{0:dd/MM/yyyy HH:mm:ss}',
        hideOnGroup: true,
        title: 'Updated',
        hidden: true
      }
    ],
    dataSource: {
      autoSync: true,
      pageSize: 25,
      schema: {
        data: "questions",
        model: {
          id: "id",
          fields: {
            id: { type: "number", editable: false },
            question_text: { type: "string", editable: false },
            rubriek_id: { type: "number", editable: false },
            rubriek_versie_id: { type: "number", editable: false },
            rubriek_nummer: { type: "string", editable: false },
            is_generic: { type: "boolean", editable: false },
            sequence_number: { type: "number", format: '{0:0}', nullable: true, validation: {min: 1, step: 1} },
            problem_count: { type: "number", editable: false },
            created_at: { type: "date", editable: false },
            updated_at: { type: "date", editable: false },
            uri: { type: "string", editable: false },
            update_sequence_number_uri: { type: "string", editable: false },
            can_show: { type: "boolean", editable: false },
            can_edit: { type: "boolean", editable: false },
            can_destroy: { type: "boolean", editable: false }
          }
        },
        total: "total"
      },
      serverFiltering: true,
      serverPaging: true,
      serverSorting: true,
      transport: {
        parameterMap: function(data, type) {
          // if (type === "update") {
          //   this.selectedRow = data;
          //   return JSON.stringify({activity_question: {activity_id: this.activityId, question_id: data.id, sequence_number: data.sequence_number} })
          // }
          if (data.filter) {
            data.filter.filters = data.filter.filters.map((filter) => {
              return {
                field: filter.field,
                operator: filter.operator,
                value: (filter.field == 'created_at' || filter.field == 'updated_at' ? kendo.toString(filter.value, 'yyyy-MM-ddTHH:mm:sszzz') : filter.value)
              };
            });
          }
          return data;
        }.bind(this),
        read: {
          cache: false,
          dataType: 'json',
          url: () => {
            const url = new URL(this.urlValue);
            if (this.activityId !== undefined)
              url.searchParams.append('activity_id', this.activityId);
            return url;
            // return `${this.urlValue}?activity_id=${this.activityId}`;
          }
        },
        update: {
          cache: false,
          contentType: 'application/json',
          data: function() {
            const selectedRows = this.grid.select();
            const rowData = this.grid.dataItem(selectedRows);
            return {
              activity_question: {
                activity_id: this.activityId,
                question_id: rowData.id,
                sequence_number: rowData.sequence_number
              }
            }
          }.bind(this),
          dataType: 'json',
          headers: { "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content") },
          type: "PUT",
          url: () => {
            // return `/admin/activities/${this.activityId}/questions/${this.selectedRow.id}`;
            // return this.selectedRow.update_sequence_number_uri;
            const selectedRows = this.grid.select();
            const rowData = this.grid.dataItem(selectedRows);
            return rowData.update_sequence_number_uri;
          }
        }
      }
    },
    editable: this.isEditableValue,
    filterable: {
      mode: "row"
    },
    groupable: this.isGroupableValue,
    loaderType: "skeleton",
    pageable: true,
    reorderable: true,
    resizable: true,
    // search: {
    //   fields: ['question_text', 'rubriek_nummer']
    // },
    sortable: true,
    change: function(e) {
      const selectedRows = this.grid.select();
      if (selectedRows.length > 0) {
        $(this.grid.element).find('.k-grid-link-button').prop('disabled', false);
        $(this.grid.element).find('.k-grid-unlink-button').prop('disabled', false);
        $(this.grid.element).find('.k-grid-copy-button').prop('disabled', false);
        const rowData = this.grid.dataItem(selectedRows);
        if (rowData.can_show)
          $(this.grid.element).find('.k-grid-show-button').prop('disabled', false);
        if (rowData.can_edit)
          $(this.grid.element).find('.k-grid-edit-button').prop('disabled', false);
        if (rowData.can_destroy)
          $(this.grid.element).find('.k-grid-destroy-button').prop('disabled', false);
        this.dispatch("select", { detail: { content: rowData.id } });
      } else {
        $(this.grid.element).find('.k-grid-link-button').prop('disabled', true);
        $(this.grid.element).find('.k-grid-unlink-button').prop('disabled', true);
        $(this.grid.element).find('.k-grid-copy-button').prop('disabled', true);
        $(this.grid.element).find('.k-grid-show-button').prop('disabled', true);
        $(this.grid.element).find('.k-grid-edit-button').prop('disabled', true);
        $(this.grid.element).find('.k-grid-destroy-button').prop('disabled', true);
        this.dispatch("unselect", { detail: { content: null } });
      }
    }.bind(this),
    columnHide: this.handleGridEvent,
    columnLock: this.handleGridEvent,
    columnReorder: this.handleGridEvent,
    columnResize: this.handleGridEvent,
    columnShow: this.handleGridEvent,
    columnStick: this.handleGridEvent,
    columnUnlock: this.handleGridEvent,
    columnUnstick: this.handleGridEvent,
    filter: this.handleGridEvent,
    group: this.handleGridEvent,
    groupCollapse: this.handleGridEvent,
    groupExpand: this.handleGridEvent,
    page: this.handleGridEvent,
    rowReorder: this.handleGridEvent,
    rowResize: this.handleGridEvent,
    sort: this.handleGridEvent,
  }
  selectedRow;

  initialize() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    if (!this.hideSequenceColumnValue) {
      this.gridConfig.columns.push({
        field: 'sequence_number',
        type: 'number',
        title: 'Volgorde',
        format: '{0:n0}',
        editor: null // must be assigned after restoreGridState
      });
    }
    if (!this.hideProblemsColumnValue) {
      this.gridConfig.columns.push({
        field: 'problem_count',
        type: 'number',
        hideOnGroup: true,
        title: 'Problemen',
        filterable: false,
        // filterable: {
        //   cell: {
        //     showOperators: false,
        //     template: function(args) {
        //       args.element.kendoRadioGroup({
        //         inputName: "problem_count",
        //         items: [{ value: true, label: "Yes" }, { value: false, label: "No" }],
        //         layout: "horizontal"
        //       });
        //       args.element.kendoDropDownList({
        //         dataSource: [{ value: 1, text: "Yes" }, { value: 0, text: "No" }],
        //         optionLabel: "--Select--",
        //         dataTextField: "text",
        //         dataValueField: "value"
        //       });
        //     }
        //   }
        // }
        sortable: false
      });
    }

    this.gridConfig = this.restoreGridState(this.gridConfig);
    this.assignFunctions();

    if (!this.hideLinkCommandValue) this.addLinkCommand();
    if (!this.hideUnlinkCommandValue) this.addUnlinkCommand();
    if (!this.hideCopyCommandValue) this.addCopyCommand();
    if (!this.hideShowCommandValue) this.addShowCommand();
    if (!this.hideEditCommandValue) this.addEditCommand();
    if (!this.hideDestroyCommandValue) this.addDestroyCommand();

    this.grid = $(this.element).kendoGrid(this.gridConfig).data("kendoGrid");

    const linkBtn = $(this.grid.element).find('.k-grid-link-button');
    linkBtn.prop('disabled', true);
    linkBtn.on('click', this.linkToActivity.bind(this));
    const unlinkBtn = $(this.grid.element).find('.k-grid-unlink-button');
    unlinkBtn.prop('disabled', true);
    unlinkBtn.on('click', this.unlinkFromActivity.bind(this));
    const copyBtn = $(this.grid.element).find('.k-grid-copy-button');
    copyBtn.prop('disabled', true);
    copyBtn.on('click', this.copyQuestion.bind(this));
    const showBtn = $(this.grid.element).find('.k-grid-show-button');
    showBtn.prop('disabled', true);
    showBtn.on('click', this.showQuestion.bind(this));
    const editBtn = $(this.grid.element).find('.k-grid-edit-button');
    editBtn.prop('disabled', true);
    editBtn.on('click', this.editQuestion.bind(this));
    const destroyBtn = $(this.grid.element).find('.k-grid-destroy-button');
    destroyBtn.prop('disabled', true);
    destroyBtn.on('click', this.destroyQuestion.bind(this));

    $(this.element).kendoTooltip({
      filter: '.k-grid-link-button',
      content: 'Koppel'
    }).data("kendoTooltip");
    $(this.element).kendoTooltip({
      filter: '.k-grid-unlink-button',
      content: 'Ontkoppel'
    }).data("kendoTooltip");
    $(this.element).kendoTooltip({
      filter: '.k-grid-copy-button',
      content: 'Dupliceer'
    }).data("kendoTooltip");
    $(this.element).kendoTooltip({
      filter: '.k-grid-show-button',
      content: 'bekijk'
    }).data("kendoTooltip");
    $(this.element).kendoTooltip({
      filter: '.k-grid-edit-button',
      content: 'wijzig'
    }).data("kendoTooltip");
    $(this.element).kendoTooltip({
      filter: '.k-grid-destroy-button',
      content: 'verwijder'
    }).data("kendoTooltip");
  }

  saveGridState() {
    const options = this.grid.getOptions();
    const strippedOptions = this.stripOptions(options);
    localStorage.setItem(this.gridNameValue, JSON.stringify(strippedOptions));
  }

  stripOptions(options) {
    const dataSource = {
      fields: options.dataSource.fields,
      filter: options.dataSource.filter,
      group: options.dataSource.group,
      page: options.dataSource.page,
      pageSize: options.dataSource.pageSize,
      select: options.dataSource.select,
      sort: options.dataSource.sort
    };
    return {
      columns: options.columns,
      dataSource: dataSource,
      version: this.version
    };
  }

  restoreGridState(defaultOptions) {
    const options = JSON.parse(localStorage.getItem(this.gridNameValue));
    if (options) {
      const {columns, dataSource, version} = options;
      if (version === undefined || version !== this.version) {
        return defaultOptions;
      }
      const mergedDataSource = Object.assign({}, defaultOptions ? defaultOptions.dataSource : {}, dataSource);
      return Object.assign({}, defaultOptions ? defaultOptions : {}, {
        columns: columns,
        dataSource: mergedDataSource
      });
    }
    return defaultOptions;
  }

  assignFunctions() {
    this.gridConfig.columns.forEach((column) => {
      if (typeof this[`${column.field}Editor`] === 'function') {
        column.editor = this[`${column.field}Editor`];
      }
      if (typeof this[`${column.field}FilterCellTemplate`] === 'function') {
        column.filterable.cell.template = this[`${column.field}FilterCellTemplate`];
      }
    });
  }

  /**
   * event listener
   * @param content
   */
  reload({ detail: { content } }) {
    $(this.element).find('.k-grid-link-button').prop('disabled', true);
    $(this.element).find('.k-grid-unlink-button').prop('disabled', true);
    $(this.element).find('.k-grid-copy-button').prop('disabled', true);
    $(this.element).find('.k-grid-show-button').prop('disabled', true);
    $(this.element).find('.k-grid-edit-button').prop('disabled', true);
    $(this.element).find('.k-grid-destroy-button').prop('disabled', true);

    this.activityId = parseInt(content);
    this.grid.dataSource.fetch();
  }

  addQuestion({ detail: { content } }) {
    this.grid.dataSource.fetch();
  }

  removeQuestion({ detail: { content } }) {
    this.grid.dataSource.fetch();
  }

  linkToActivity(event) {
    event.preventDefault();
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    const data = {
      activity_question: {
        activity_id: this.activityId,
        question_id: rowData.id,
        sequence_number: null
      }
    };
    $.ajax({
      cache: false,
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json',
      type: "POST",
      url: `/admin/activities/${this.activityId}/questions`,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, textStatus, jqXHR) {
        // this.grid.removeRow(this.grid.select());
        // this.grid.removeRow(`tr[data-uid='${$(this.grid.select()).data("uid")}']`);
        this.grid.dataSource.fetch();
        this.dispatch("link", { detail: { content: JSON.stringify(rowData) } } );
      }.bind(this),
      error: function(jqXHR, textStatus, errorThrown) {
        const response = JSON.parse(jqXHR.responseText);
        kendo.alert(response.message);
      }
    });
  }

  unlinkFromActivity(event) {
    event.preventDefault();
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    $.ajax({
      dataType: 'json',
      type: "DELETE",
      url: `/admin/activities/${this.activityId}/questions/${rowData.id}`,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, textStatus, jqXHR) {
        this.grid.dataSource.fetch();
        this.dispatch("unlink", { detail: { content: JSON.stringify(rowData) } } );
      }.bind(this),
      error: function(jqXHR, textStatus, errorThrown) {
        const response = JSON.parse(jqXHR.responseText);
        kendo.alert(response.message);
      }
    });
  }

  copyQuestion(event) {
    event.preventDefault();
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    Turbo.visit(`${rowData.uri}/copy`);
  }

  showQuestion(event) {
    event.preventDefault();
    // const rowData = this.dataItem($(event.target).closest("tr"));
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    Turbo.visit(rowData.uri);
  }

  editQuestion(event) {
    event.preventDefault();
    // const rowData = this.dataItem($(event.target).closest("tr"));
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    Turbo.visit(`${rowData.uri}/edit`);
  }

  destroyQuestion(event) {
    event.preventDefault();
    // const grid = this.element.data('kendoGrid');
    // const rowData = this.dataItem($(event.target).closest("tr"));
    const grid = this.grid;
    const selectedRows = this.grid.select();
    const rowData = this.grid.dataItem(selectedRows);
    kendo.confirm(`Ben je zeker dat je deze vraag wil <strong>verwijderen</strong>?`).done(function() {
      $.ajax({
        dataType: 'json',
        type: 'DELETE',
        url: `${rowData.uri}.json`,
        beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
        success: function(data, textStatus, jqXHR) {
          // grid.removeRow(`tr[data-uid='${rowData.uid}']`);
          grid.dataSource.fetch();
        },
        error: function(jqXHR, textStatus, errorThrown) {
          const response = JSON.parse(jqXHR.responseText);
          kendo.alert(response.message);
        }
      });
    });
  }

  addLinkCommand() {
    this.gridConfig.autoBind = false;
    this.gridConfig.selectable = "row";
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "link-button",
      text: "Koppel",
      iconClass: "k-icon k-i-link"
    });
  }

  addUnlinkCommand() {
    this.gridConfig.autoBind = false;
    this.gridConfig.selectable = "row";
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "unlink-button",
      text: "Ontkoppel",
      iconClass: "k-icon k-i-unlink"
    });
  }

  addCopyCommand() {
    this.gridConfig.selectable = "row";
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "copy-button",
      text: "Dupliceer",
      iconClass: "k-icon k-i-copy"
    });
  }

  addShowCommand() {
    // this.addActionsColumn();
    // this.gridConfig.columns[this.actionColumnIndex].width += this.commandButtonWidth;
    // this.gridConfig.columns[this.actionColumnIndex].command.push({
    //   name: "show-button",
    //   text: " ",
    //   iconClass: "k-icon k-i-eye",
    //   click: this.showQuestion,
    //   visible: function(rowData) { return rowData.can_show; }
    // });
    this.gridConfig.selectable = true;
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "show-button",
      text: "Bekijk",
      iconClass: "k-icon k-i-eye"
    });
  }

  addEditCommand() {
    // this.addActionsColumn();
    // this.gridConfig.columns[this.actionColumnIndex].width += this.commandButtonWidth;
    // this.gridConfig.columns[this.actionColumnIndex].command.push({
    //   name: "edit-button",
    //   text: " ",
    //   iconClass: "k-icon k-i-pencil",
    //   click: this.editQuestion,
    //   visible: function(rowData) { return rowData.can_edit; }
    // });
    this.gridConfig.selectable = true;
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "edit-button",
      text: "Wijzig",
      iconClass: "k-icon k-i-pencil"
    });
  }

  addDestroyCommand() {
    // this.addActionsColumn();
    // this.gridConfig.columns[this.actionColumnIndex].width += this.commandButtonWidth;
    // this.gridConfig.columns[this.actionColumnIndex].command.push({
    //   name: "destroy-button",
    //   text: " ",
    //   iconClass: "k-icon k-i-trash",
    //   click: this.destroyQuestion,
    //   visible: function(rowData) { return rowData.can_destroy; }
    // });
    this.gridConfig.selectable = true;
    if (this.gridConfig.toolbar === undefined) {
      this.gridConfig.toolbar = [];
    }
    this.gridConfig.toolbar.push({
      name: "destroy-button",
      text: "Verwijder",
      iconClass: "k-icon k-i-trash"
    });
  }

  addActionsColumn() {
    if (typeof this.actionColumnIndex == 'number') return;
    this.gridConfig.columns.push({
      title: 'Acties',
      command: [],
      width: 0
    });
    this.actionColumnIndex = this.gridConfig.columns.length - 1;
  }
}
