// import { Controller } from "@hotwired/stimulus"
import ActivitiesController from './activities_controller.js'
import * as bootstrap from 'bootstrap'

export default class extends ActivitiesController {

    static values = {
        autoLoad: { type: Boolean, default: true }
    }

    selectedActivityId;
    selectedActivityName;
    selectedActivityPublished;
    selectedGroupId;
    selectedGroupName;
    selectedGroupPublished;
    questionId;
    modal;

    connect() {
        if (this.autoLoadValue) {
            super.fetch(this.urlValue).then((value) => {
                var $form = this.element.querySelector('form[name="form-activities"]');
                if ($form) {
                    var $groups = $form.querySelectorAll('.accordion-button');
                    $groups.forEach($group => {
                       $group.addEventListener('click', this.groupClicked.bind(this));
                    });
                }
            });
        }
    }

    toggleActivity(value, checked) {}

    reload({ detail: { content }}) {
        const url = new URL(this.urlValue);
        if (content != null) {
            this.questionId = parseInt(content);
            url.searchParams.append('question_id', this.questionId);
            super.fetch(url).then((value) => {
                var $form = this.element.querySelector('form[name="form-activities"]');
                if ($form) {
                    var $groups = $form.querySelectorAll('.accordion-button');
                    $groups.forEach($group => {
                        $group.addEventListener('click', this.groupClicked.bind(this));
                    });
                }

                this.searchElement.dispatchEvent(new Event("input"));
                this.selectedActivityId = null;
                this.selectedGroupId = null;
                $(this.element.querySelector('#linkBtn')).prop("disabled", true);
            });
        } else {
            if (this.autoLoadValue) {
                super.fetch(url).then((value) => {
                    var $form = this.element.querySelector('form[name="form-activities"]');
                    if ($form) {
                        var $groups = $form.querySelectorAll('.accordion-button');
                        $groups.forEach($group => {
                            $group.addEventListener('click', this.groupClicked.bind(this));
                        });
                    }

                    this.searchElement.dispatchEvent(new Event("input"));
                    this.selectedActivityId = null;
                    this.selectedGroupId = null;
                    this.dispatch("select", { detail: { content: null } });
                });
            } else {
                this.questionId = undefined;
                this.selectedActivityId = null;
                this.selectedGroupId = null;
                $(this.element.querySelector('#linkBtn')).prop("disabled", true);
                $(this.element).find('.accordion').empty();
            }
        }
    }

    activityClicked(event) {
        if (this.selectedGroupId) {
            $(`button[data-group-id="${this.selectedGroupId}"]`).removeClass('selected');
        }
        if (this.selectedActivityId) {
            $(`input[value="${this.selectedActivityId}"]`).siblings('label').removeClass('selected');
        }
        $(event.currentTarget).siblings('label').addClass('selected');
        this.selectedActivityId = event.currentTarget.value;
        this.selectedActivityName = $(event.currentTarget).data('activity-name');
        this.selectedActivityPublished = $(event.currentTarget).data('published');
        this.selectedGroupId = null;
        $(this.element.querySelector('#linkBtn')).prop("disabled", this.selectedActivityId == null ? true : false);
        $(this.element.querySelector('#delBtn')).prop("disabled", this.selectedActivityId == null ? true : false);
        $(this.element.querySelector('#editBtn')).prop("disabled", this.selectedActivityId == null ? true : false);
        this.dispatch("select", { detail: { content: event.currentTarget.value } });
    }

    groupClicked(event) {
        if (this.selectedActivityId) {
            $(`input[value="${this.selectedActivityId}"]`).siblings('label').removeClass('selected');
        }
        if (this.selectedGroupId) {
            $(`button[data-group-id="${this.selectedGroupId}"]`).removeClass('selected');
        }
        $(event.currentTarget).addClass('selected');
        this.selectedGroupId = $(event.currentTarget).data('group-id');
        this.selectedGroupName = $(event.currentTarget).html();
        this.selectedGroupPublished = $(event.currentTarget).data('published');
        this.selectedActivityId = null;
        $(this.element.querySelector('#delBtn')).prop("disabled", this.selectedGroupId == null ? true : false);
        $(this.element.querySelector('#editBtn')).prop("disabled", this.selectedGroupId == null ? true : false);
    }

    linkToQuestion(event) {
        event.preventDefault();
        const data = {
            activity_question: {
                activity_id: this.selectedActivityId,
                question_id: this.questionId,
                sequence_number: null
            }
        };
        $.ajax({
            cache: false,
            contentType: 'application/json',
            data: JSON.stringify(data),
            datatype: 'json',
            type: "POST",
            url: `/admin/questions/${this.questionId}/activities`,
            beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
            success: function(data, textStatus, jqXHR) {
                this.reload({detail: {content: this.questionId}});
                this.dispatch("link", { detail: { content: this.questionId } } );
            }.bind(this),
            error: function(jqXHR, textStatus, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                kendo.alert(response.message);
            }
        });
    }

    unlinkFromQuestion(event) {
        event.preventDefault();
        $.ajax({
            dataType: 'json',
            type: "DELETE",
            url: `/admin/questions/${this.questionId}/activities/${this.selectedActivityId}`,
            beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
            success: function(data, textStatus, jqXHR) {
                this.reload({detail: {content: this.questionId}});
                this.dispatch("unlink", { detail: { content: this.questionId } } );
            }.bind(this),
            error: function(jqXHR, textStatus, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                kendo.alert(response.message);
            }
        });
    }

    showCreateModal(event) {
        const modalElement = document.getElementById('activity-modal');
        if (this.selectedGroupId != null)
            $(modalElement.querySelector('.modal-title')).html('Toevoegen Activiteit');
        else
            $(modalElement.querySelector('.modal-title')).html('Toevoegen Activiteitengroep');
        $(modalElement.querySelector('.modal-footer')).children('.btn-primary').remove();
        $(modalElement.querySelector('.modal-footer')).append('<button class="btn btn-primary" type="button" data-action="click->backend-activities#createActivity" aria-label="Voeg toe">Voeg toe</button>');
        this.modal = new bootstrap.Modal(modalElement, {
            backdrop: true,
            focus: true,
            keyboard: true
        });
        this.modal.show();
    }

    showEditModal(event) {
        const modalElement = document.getElementById('activity-modal');
        if (this.selectedActivityId != null) {
            $(modalElement.querySelector('.modal-title')).html('Wijzig Activiteitnaam');
            $(modalElement.querySelector('#name-input')).val(this.selectedActivityName);
            $(modalElement.querySelector('#publish-check')).prop('checked', this.selectedActivityPublished);
        } else if (this.selectedGroupId != null) {
            $(modalElement.querySelector('.modal-title')).html('Wijzig Activiteitengroepnaam');
            $(modalElement.querySelector('#name-input')).val(this.selectedGroupName);
            $(modalElement.querySelector('#publish-check')).prop('checked', this.selectedGroupPublished);
        }
        $(modalElement.querySelector('.modal-footer')).children('.btn-primary').remove();
        $(modalElement.querySelector('.modal-footer')).append('<button class="btn btn-primary" type="button" data-action="click->backend-activities#updateActivity" aria-label="Voeg toe">Wijzig</button>');
        this.modal = new bootstrap.Modal(modalElement, {
            backdrop: true,
            focus: true,
            keyboard: true
        });
        this.modal.show();
    }

    createActivity(event) {
        this.modal.hide();
        const activity_text = $('#name-input').val();
        const publish = $('#publish-check').is(':checked');
        const data = {
            activity: {
                activity_text: activity_text,
                publish: publish
            }
        };
        if (this.selectedGroupId) {
            data.activity.parent_id = this.selectedGroupId;
        }
        $.ajax({
            cache: false,
            contentType: 'application/json',
            data: JSON.stringify(data),
            dataType: 'json',
            type: "POST",
            url: '/admin/activities',
            beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
            success: function(data, textStatus, jqXHR) {
                this.reload({detail: {content: null}});
            }.bind(this),
            error: function(jqXHR, textStatus, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                kendo.alert(response.message);
            }
        });
    }

    updateActivity(event) {
        this.modal.hide();
        const activity_text = $('#name-input').val();
        const published = $('#publish-check').is(':checked');
        const data = {
            activity: {
                id: this.selectedActivityId,
                activity_text: activity_text,
                publish: published
            }
        };
        if (this.selectedGroupId) {
            data.activity.id = this.selectedGroupId;
        } else {
            data.activity.id = this.selectedActivityId;
        }
        $.ajax({
            cache: false,
            contentType: 'application/json',
            data: JSON.stringify(data),
            dataType: 'json',
            type: "PUT",
            url: `/admin/activities/${data.activity.id}`,
            beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
            success: function(data, textStatus, jqXHR) {
                this.reload({detail: {content: null}});
            }.bind(this),
            error: function(jqXHR, textStatus, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                kendo.alert(response.message);
            }
        });
    }

    deleteActivity(event) {
        kendo.confirm(`Ben je zeker dat je deze activiteit wil <strong>verwijderen</strong>?`).done(function() {
            let activityId;
            if (this.selectedGroupId) {
                activityId = this.selectedGroupId;
            } else {
                activityId = this.selectedActivityId;
            }
            $.ajax({
                cache: false,
                dataType: 'json',
                type: "DELETE",
                url: `/admin/activities/${activityId}`,
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
                },
                success: function (data, textStatus, jqXHR) {
                    this.reload({detail: {content: null}});
                }.bind(this),
                error: function (jqXHR, textStatus, errorThrown) {
                    const response = JSON.parse(jqXHR.responseText);
                    kendo.alert(response.message);
                }
            });
        }.bind(this));
    }
}
